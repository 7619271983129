<template>
  <div class="add_campaign">
    <b-card>
      <div class="heading">
        <p>
          Add Campaign
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="addCampaignForm">
        <b-form
          class="mt-2"
          style="
          width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Campaign Name"
              label-for="blog-edit-title"
              class="mb-2 campaignLabel"
            >
              <validation-provider
                v-slot="{ errors }"
                name="campaign name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="blogData.name"
                  placeholder="Please enter Campaign name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- devices select-->
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              label="Devices"
              label-for="blog-edit-category"
              class="mb-2 campaignLabel"
            >
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="filteredDevicesOptions"
                class="search"
                @input="selectDevicesFunc(selected)"
                @search="onSearch"
              />
            </b-form-group>
          </b-col>
          <!-- devices Group-->
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              label="Devices Groups"
              label-for="blog-edit-category"
              class="mb-2 campaignLabel"
            >
              <v-select
                v-model="groupSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="filteredGroupsOptions"
                class="search"
                @input="selectGroupFunc(groupSelected)"
              />
            </b-form-group>
          </b-col>
          <b-col />
        </b-form>
      </validation-observer>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="maxTimeLimit"
        @addMediaToCampaign="addMediaToCampaignFile"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        @removeMediaFromList="deleteMediaFromCampaign"
      />
      <div class="d-flex justify-content-center mt-0 ">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewCampaign"
        >
          Review
        </b-button>
        <b-button
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="createCampaign"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Create
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelCampaign"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, VBModal, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import MediaType from '@/common/enums/mediaTypeEnum'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'

export default {
  components: {
    AddedMediaToCampaign,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BannerAdsMedia,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      maxTimeLimit: 120,
      MediaType,
      addedFilesToCampaign: [],
      spinner: false,
      search: '',
      selected: [],
      allDevices: [],
      allGroups: [],
      groupSelected: [],
      blogData: {
        name: '',
        banner_has_group: [],
        banner_has_device: [],
        banner_has_media: [],
      },
      // validation
      required,
    }
  },
  computed: {
    filteredDevicesOptions() {
      return this.allDevices.filter(n => !this.selected.map(device => device.id).includes(n.id))
    },
    filteredGroupsOptions() {
      return this.allGroups.filter(n => !this.groupSelected.map(device => device.id).includes(n.id))
    },
    // addedFilesDuration() {
    //   let durationTotal = 0
    //   this.addedFilesToCampaign.forEach(({ duration }) => {
    //     durationTotal += duration
    //   })
    //   return durationTotal
    // },
  },
  mounted() {
    this.getAllDevices()
    this.getAllGroups()
  },
  methods: {
    async onSearch(query, loading) {
      this.search = query
      if (this.search.length) {
        loading(true)
        await this.getAllDevices()
        loading(false)
      } else {
        loading(false)
      }
    },
    async getAllDevices() {
      try {
        const {
          data: {
            data,
          },
        } = await this.$axios.get(`user/device?offset=${0}&limit=${20}&search_query=${this.search}`)
        this.allDevices = data.results
      } catch {
        //
      }
    },
    getAllGroups() {
      this.$axios.get('groups').then(({ data }) => {
        this.allGroups = data.data
      }).catch(() => this.$swal('Error in fetching Groups Data'))
    },
    updateIdsOfMediaInPayload() {
      this.blogData.banner_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    addMediaToCampaignFile(object) {
      this.addedFilesToCampaign.push(object)
      this.updateIdsOfMediaInPayload()
    },
    deleteMediaFromCampaign(index) {
      this.addedFilesToCampaign.splice(index, 1)
    },
    selectDevicesFunc(selected) {
      this.blogData.banner_has_device = selected.map(res => res.id)
    },
    selectGroupFunc(groupSelected) {
      this.blogData.banner_has_group = groupSelected.map(res => res.id)
    },
    async createCampaign() {
      this.$refs.addCampaignForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          await this.addNewBannerCampaign()
          this.spinner = false
        }
      })
    },
    async addNewBannerCampaign() {
      try {
        await this.$axios
          .post('banner-campaigns', this.blogData)
        await this.$router.push('/banner-ads')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message)
      }
    },
    cancelCampaign() {
      this.$router.push('/banner-ads')
    },
    async reviewCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
  },
}
</script>

<style src="../../../node_modules/vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.add_campaign {
  .search {
      height: 42px !important;
    .vs__search, .vs__search:focus{
      height:36px !important;
    }
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
    }
    .vs__dropdown-toggle{
      max-height: 70px !important;
      overflow-y: auto !important;
    }
    .vs__search, .vs__search:focus{
      font-size: medium !important;
    }
  }
}
.heading{
  font-size: 20px;
  line-height: 44px;
  color: #1f58b5;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: center;
}
.mediaText{
  font-size: 14px;
  line-height: 22px;
  color: #323232;
  font-weight: 600;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  margin-top: 2rem;

}
.addDevice{
 width: 171px;
height: 65px;
border-radius: 10px;
background-color: #ffffff;
padding: 25px;
}
.text{
font-size: 12px;
color: #5e5873;
font-weight: 500;
font-family: "Montserrat";
}
.ml-0{
  font-size: 10px;
color: #6e6b7b;
font-weight: 400;
font-family: "Montserrat";
}
.choose{
  display: flex;
  background-color: #e0dee1 !important;
  color: #6e6b7b !important;
  border-color: #e0dee1 !important;
}
.modal1{
  padding: 0 !important;
}
</style>
